<template>
  <div class="person">

    <section
      v-if="!isLogin"
      class="person-head"
    >
      <div>
        <van-image
          width="100"
          height="100"
          round
          :src='require("../../assets/image/log.png")'
        />

      </div>
      <div class="person-head-t"><span @click="onLogin">登录/注册</span></div>
    </section>
    <section
      v-else
      class="person-h section"
    >
      <div class="person-l">
        <van-image
          width="60"
          height="60"
          round
          :src="userInfos.avatarUrl ? userInfos.avatarUrl : avatarUrl"
        />

      </div>
      <div class="person-r">
        <div v-if="isLogin">
          <div class="person-h-t1">
            {{userInfos.nickName}}
            <!-- <span>{{userType === 0 ? '游客': '合伙人' }}</span> -->
          </div>
          <!-- <div class="person-h-t2">申请专属口令</div>
          <div class="person-h-t3">我的邀请码: {{code}} <span @click="onCopy($event)">复制</span></div> -->
        </div>
        <!-- <div v-else>
          <van-button
            color="#494949"
            size="small"
            plain
            @click="onLogin"
            style="padding: 0 16px"
          > 登录 </van-button>
        </div> -->
      </div>
      <!-- <div
        class="person-rr"
        v-if="userType > 0"
      >
        <van-icon
          name="arrow"
          @click="onUser"
        />
      </div> -->
    </section>
    <section
      class="person-s1 section"
      v-if="userType > 0 && userType < 4"
    >

      <div class="person-vv">
        <div class="person-v-h person-p">
          <div class="person-v-hl">
            可提现： <span>{{money}}</span>
          </div>
          <div class="person-v-hr">
            <div
              class="person-v-hrt"
              @click="withdrawClick"
            >立即提现</div>
          </div>
        </div>
        <div class="person-v-m person-p">
          <div
            class="person-v-mm"
            v-for="(item,index) in incomeList"
            :key='index'
          >
            <div>￥{{item.value}}</div>
            <div>{{item.lable}}</div>
          </div>
        </div>
      </div>

    </section>
    <!-- 团队管理：渠道用户可见-->
    <section
      class="person-s2 section"
      v-if="userType > 0"
    >
      <div class="person-team">

        <div
          class="team-list"
          v-for="(item,index) in teamList"
          :key='index'
          @click="orderTap(item)"
        >
          <div class="team-img">
            <van-image :src="item.icon" />
          </div>
          <div class="team-tit">{{item.name}}</div>
        </div>
      </div>
    </section>
    <section
      class="person-s3 section"
      :class="{'person-no':!isLogin}"
    >
      <div class="person-order-m">
        <div class="person-order-mh">
          <van-cell
            title="我的订单"
            is-link
            value="全部订单"
            @click="onAllOrders"
          />
        </div>
        <div class="person-order-mf">
          <div
            class="person-order-list"
            v-for="(item,index) in orderList"
            :key='index'
            @click="onOrders(item)"
          >
            <div class="person-order-img">
              <van-image :src="item.icon" />
            </div>
            <div class="person-order-tit">{{item.name}}</div>
          </div>
        </div>
      </div>
    </section>
    <!-- 申请成为分销商户：正常用户可见 -->
    <section
      class="person-s4 section"
      v-if="userType ===0"
    >号卡明细
      <van-cell
        title="申请成为分销商户"
        is-link
        to="/applyEer"
      />
    </section>
    <section class="person-s5">
      <van-cell
        v-if="userType > 0"
        title="号卡明细"
        @click="onCellAddress(3)"
        :icon="require('../../assets/image/person/hk.png')"
        is-link
      />
      <van-cell
        v-if="userType > 0"
        title="物流查询"
        @click="onCellAddress(4)"
        :icon="require('../../assets/image/person/hk.png')"
        is-link
      />
      <van-cell
        class="van-mm"
        v-if="userType > 0"
        title="推广二维码"
        to='/share'
        :icon="require('../../assets/image/person/mm.png')"
        is-link
      />
      <!-- to="/address" -->
      <van-cell
        v-if="userType === 0"
        title="管理收货地址"
        is-link
        @click="onCellAddress(1)"
        :icon="require('../../assets/image/person/ads.png')"
      />
      <van-cell
        title="联系客服"
        :icon="require('../../assets/image/person/kf.png')"
        is-link
        @click='onSerClick'
      />
      <!-- 收支明细：渠道用户可见-->
      <van-cell
        v-if="userType > 0"
        title="收支明细"
        @click="onCellAddress(2)"
        :icon="require('../../assets/image/person/min.png')"
        is-link
      />

      <van-cell
        v-if="userType > 0"
        title="修改密码"
        to='/changPwd'
        :icon="require('../../assets/image/person/pwd.png')"
        is-link
      />
      <van-cell
        title="退出登录"
        :icon="require('../../assets/image/person/out.png')"
        is-link
        @click='onOutClick'
      />
    </section>
    <van-overlay
      :show="serviceShow"
      @click="onSerClickHide"
    >
      <div class="overlay-main">

        <div class="overlay-tit">如有疑问请咨询售后服务热线</div>
        <div class="overlay-tel">
          Tel：<span>400-9987511</span>
        </div>
        <div class="overlay-cen">
          <a href="tel:400-998-7511">
            <van-icon
              name="phone-circle"
              :color="variables.textColor"
              size="36"
            />
          </a>

        </div>

      </div>
    </van-overlay>
    <div
      class="Mask"
      v-if="cardShow"
    ></div>
    <div
      class="explain"
      v-if="cardShow"
    >
      <div class="tan">
        <img
          src="../../assets/image/vacation.png"
          alt=""
        />
      </div>
      <div class="tan1">
        <img
          src="../../assets/image/xx.png"
          alt=""
          @click="qux()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import clipboard from "@/utils/clipboard";
import page from "../../utils/data.js";
import { Dialog, Toast } from "vant";
import variables from "@/styles/variables.scss";
import API from "../../api/http";
export default {
  name: "person",
  components: {},
  data() {
    return {
      cardShow: false,
      // name: "",
      // tag: "合伙人",
      // code: "45698", //邀请码
      money: 0,
      incomeList: [
        {
          lable: "昨日收入",
          value: 0,
        },
        {
          lable: "今日收入",
          value: 0,
        },
        {
          lable: "现有余额",
          value: 0,
        },
      ],
      teamList: page.teamList,
      orderList: page.orderList,
      serviceShow: false,
      avatarUrl: require("../../assets/image/user1.png"),
    };
  },
  computed: {
    variables() {
      return variables;
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    userType() {
      return this.$store.getters["user/userType"];
    },
    userInfos() {
      return this.$store.getters["user/userInfos"];
    },
  },
  created() {
    if (this.$route.query.id == 1) {
      // if (this.$route.params.typeDialag) {
      this.cardShow = true;
      // }
    }

    //4级代理没有 我的团队
    console.log("kkk", this.isLogin);
    if (this.userType === 4) {
      this.teamList = page.teamList.slice(1);
      // console.log("kk", this.teamList);
    }
    //4级代理和普通用户没有提现功能
    if (this.isLogin) {
      if (this.userType > 0 && this.userType < 4) {
        this.getMOney();
      }
    }
  },
  methods: {
    qux() {
      this.cardShow = false;
    },
    onLogin() {
      // 登录、注册
      this.$router.push("/login");
    },
    getMOney() {
      //获取提现金额
      API.getUserProfile().then((res) => {
        let reault = res.data;
        this.money = reault.balance;
        this.incomeList[0].value = reault.balanceYesterIncome;
        this.incomeList[1].value = reault.balanceTodayIncome;
        // this.incomeList[2].value =
        //   reault.balanceYesterIncome + reault.balanceTodayIncome;
        this.incomeList[2].value = reault.unPayoffBalance;
      });
    },
    withdrawClick() {
      //提现
      if (!this.isLogin) {
        Toast("请先登录");
        return;
      }
      this.$router.push("/withdraw");
    },
    orderTap(item) {
      // 我的团队
      if (!this.isLogin) {
        Toast("请先登录");
        return;
      }
      if (item.type === "1") {
        //团队管理
        this.$router.push({
          path: "/team",
          query: {
            active: item.type,
          },
        });
      }
      if (item.type === "2") {
        this.$router.push("/myCommission");
      }
      if (item.type === "3") {
        //订单详情
        this.$router.push("/orderDetail");
      }
      if (item.type === "4") {
        //订单统计
        this.$router.push("/orderSta");
      }
    },
    onAllOrders() {
      //to="order?active=0" 全部订单
      if (!this.isLogin) {
        Toast("请先登录");
        return;
      }
      this.$router.push({
        path: "/order",
        query: {
          active: 0,
        },
      });
    },
    onOrders(item) {
      if (!this.isLogin) {
        Toast("请先登录");
        return;
      }
      //我的订单
      if (item.type !== "5") {
        this.$router.push({
          path: "/order",
          query: {
            active: item.type,
          },
        });
      } else {
        this.serviceShow = true;
      }
    },
    onCellAddress(val) {
      if (!this.isLogin) {
        Toast("请先登录");
        return;
      }
      if (val === 1) {
        this.$router.push("/address");
      } else if (val === 2) {
        this.$router.push("/revenue");
      } else if (val === 3) {
        this.$router.push("/cardCut");
      } else if (val === 4) {
        this.$router.push("/logisticsCheck");
      }
    },
    onSerClick() {
      // 联系客服
      this.serviceShow = true;
    },
    onOutClick() {
      if (!this.isLogin) {
        return;
      }
      //退出登录
      Dialog.confirm({
        title: "提示",
        message: "是否确定退出?",
      })
        .then(() => {
          // on confirm
          this.$store
            .dispatch("user/logout")
            .then(() => {
              this.$router.go(0);
            })
            .catch(() => {
              console.log("失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    onSerClickHide() {
      this.serviceShow = false;
    },
    onUser() {
      //个人中心
      this.$router.push("/userCenter");
    },
    onCopy(event) {
      //复制
      clipboard(this.code, event);
    },
  },
};
</script>
<style lang='scss'>
@import "../../styles/mixin.scss";
.van-mm .van-cell__left-icon {
  font-size: 14px;
}
.person {
  padding: 12px 12px 60px 12px;
}
.section {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 12px;
  align-items: center;
}
.person-s4,
.person-s5 {
  overflow: hidden;
  border-radius: 9px;
}
.person-r > button {
  font-size: 14px;
  display: inline-block;
  margin-top: 15px;
}
.person-l {
  width: 70px;
  height: 70px;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  line-height: 70px;
  span {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    cursor: pointer;
  }
}
.person-head {
  background: url("../../assets/image/head.png") no-repeat center;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 40px;
  height: 300px;
  text-align: center;
  margin-top: -12px;
  margin-left: -12px;
  margin-right: -12px;
  .person-head-t {
    color: #fff;
    padding-top: 16px;
    span {
      cursor: pointer;
      font-size: 16px;
    }
  }
}
.person-no {
  margin-top: -90px !important;
}
.person-rr {
  width: 20px;
}
.person-r {
  flex: 1;
  box-sizing: border-box;
  padding-left: 6px;
  color: #737373;
  font-size: 12px;
  text-align: left;
}
.person-h-t1 {
  font-size: 20px;
  display: flex;
  align-items: center;
}
.person-h-t1 span {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  padding: 2px 10px;
  text-align: center;
  background: #000000;
  border-radius: 9px;
  margin-left: 8px;
}

.person-h-t2 {
  width: 100px;
  height: 16px;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
  margin: 4px 0;
  font-size: 12px;
  color: #363636;
}
.person-h-t3 {
  font-size: 12px;
  span {
    padding-left: 10px;
  }
}

.person-vv {
  width: 100%;
  background: linear-gradient(90deg, #f67743 0%, #f75d22 100%);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px 12px;
}
.person-p {
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  .person-v-mm {
    font-size: 12px;
    & > div:first-child {
      font-size: 14px;
    }
  }
}
.person-p > div {
  flex: 1;
}
.person-v-h {
  padding: 10px 0 20px 0;
}
.person-v-hr {
  text-align: right !important;
}
.person-v-hrt {
  width: 80px;
  height: 26px;
  line-height: 28px;
  background: #ffffff;
  border-radius: 13px;
  color: $textColor;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.person-v-m {
  padding-bottom: 15px;
  text-align: center;
}
.person-v-hl {
  padding-left: 20px;
}
.person-s2 {
  background: #fff;
  padding: 14px 0;
  border-radius: 6px;
  margin-top: 16px;
  overflow: hidden;
}
.person-s3 {
  background: #fff;
  padding-bottom: 14px;
  border-radius: 6px;
  margin-top: 16px;
  overflow: hidden;
}
.person-team {
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  display: flex;
}
.team-list {
  // width: 25%;
  flex: 1;
  float: left;
  color: #292929;
  font-size: 14px;
}
.team-img {
  width: 30px;
  height: 30px;
  display: inline-block;
}

.person-order {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 11px;

  margin-bottom: 12px;
}
.person-order-m {
  width: 100%;
}
.person-order-mh {
  border-bottom: 1px solid #ebedf0;
}
.person-order-mf {
  padding: 20px 0;
  overflow: hidden;
}
.person-order-list {
  width: 20%;
  float: left;
  font-size: 14px;
  color: #292929;
  text-align: center;
}
.person-order-img {
  width: 24px;
  height: 24px;
  display: inline-block;
}
.person-order-mf {
  padding: 20px 0;
  overflow: hidden;
  overflow: hidden;
}
.person-order-list {
  width: 20%;
  float: left;
  font-size: 14px;
  color: #292929;
  text-align: center;
}
.person-order-img {
  width: 30px;
  height: 30px;
  display: inline-block;
}
/* 弹框样式 */
.overlay-main {
  width: 88%;
  margin: auto;
  background-color: #fff;
  position: relative;
  top: 40%;
  box-sizing: border-box;
  padding: 20px 0;
  border-radius: 11px;
  text-align: center;
}

.overlay-cen {
  width: 100%;
  box-sizing: border-box;
}

.overlay-btn {
  margin-top: 20px;
  text-align: center;
}
.overlay-tel {
  padding: 16px;
}
.overlay-tel text {
  color: $textColor;
}
.explain {
  width: 80%;
  position: absolute;
  text-align: center;
  max-width: 20rem;
  top: 10%;
  margin: auto;
  left: 0;
  z-index: 2;
  right: 0;
}
.Mask {
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  text-align: center;
  max-width: 20rem;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
.tan {
  text-align: center;

  img {
    width: 100%;
    border-radius: 20px;
  }
}

.tan1 {
  text-align: center;
  img {
    width: 30px;
  }
}
</style>
